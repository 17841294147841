import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { KendoGridDataConfig } from '../../../_foundation/kendo/models/grid.models';
import { PtKendoGridDirective } from '../../../_foundation/kendo/directives/pt-kendo-grid/pt-kendo-grid.directive';
import gridConfig from './role-grid-config';
import { VesselRoleService } from '../../services/role/vessel-role.service';
import { ConfirmDialogComponent } from '../../../_shared/components/confirm-dialog/confirm-dialog.component';
import { VesselUserService } from '../../services/user/vessel-user.service';
import { RolePermissions, UserPermissions } from '../../models/vessel-security.models';

@Component({
  selector: 'app-vessel-role-grid',
  templateUrl: './vessel-user-role-grid.component.html',
  styleUrls: ['./vessel-user-role-grid.component.scss']
})
export class VesselUserRoleGridComponent implements OnInit {
  @ViewChild('grid') grid?: GridComponent;
  @ViewChild('ptKendoGrid') ptKendoGrid: PtKendoGridDirective;
  @ViewChild('confirmDialog') confirmDialog: ConfirmDialogComponent;

  permissions: RolePermissions = new RolePermissions();
  userPermissions: UserPermissions = new UserPermissions();

  selectedRole?: number;
  name = 'roles';
  gridConfig = gridConfig;
  gridDataConfig: KendoGridDataConfig;
  state: State = {
    take: 15,
    sort: gridConfig.defaultSort
  };
  filters?: any;
  data: GridDataResult;
  exportDataLoading: boolean;

  constructor(
    private roleService: VesselRoleService,
    private userService: VesselUserService
  ) {
  }

  ngOnInit() {
    this.gridDataConfig = this.roleService.getGridDataConfig();

    this.roleService.getPermissions()
      .subscribe(result => {
        this.permissions = result;
      });

    this.userService.getPermissions()
      .subscribe(result => {
        this.userPermissions = result;
      });
  }

  resetColumns(): void {
    this.ptKendoGrid
      .resetGrid({columns: true})
      .subscribe();
  }

  resetFilters(): void {
    this.ptKendoGrid
      .resetGrid({state: true, otherFilters: true})
      .subscribe();
  }

  exportGridData() {
    this.exportDataLoading = true;

    this.ptKendoGrid.exportGridData().subscribe({
      next: () => {
        this.exportDataLoading = false;
      },
      error: () => {
        this.exportDataLoading = false;
      },
    });
  }

  archive(id: number, setTo: boolean): void {
    if (setTo) {
      this.roleService.archiveRole(id).subscribe(value => {
        this.ptKendoGrid.resetGrid({
          columns: false,
          otherFilters: false,
          state: false,
          update: true
        }).subscribe();
      });
    } else {
      this.roleService.enableRole(id).subscribe(value => {
        this.ptKendoGrid.resetGrid({
          columns: false,
          otherFilters: false,
          state: false,
          update: true
        }).subscribe();
      });
    }
  }

  confirmDelete(id: number): void {
    this.selectedRole = id;
    this.confirmDialog.open();
  }

  delete(): void {
    this.roleService.deleteRole(this.selectedRole).subscribe(value => {
      this.ptKendoGrid.resetGrid({
        columns: false,
        otherFilters: false,
        state: false,
        update: true
      }).subscribe();
      this.selectedRole = null;
    });
  }
}
