import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BulkSelectorService {

  searchTermChange$: EventEmitter<string> = new EventEmitter();

  constructor() { }

  search(searchTerm: string) {
    this.searchTermChange$.emit(searchTerm);
  }
}
