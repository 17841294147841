import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-dialog',
  templateUrl: './grid-dialog.component.html',
  styleUrls: ['./grid-dialog.component.scss']
})
export class GridDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
