import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VesselRoleService } from '../../services/role/vessel-role.service';
import { RolePermissions, UserOption, UserPermissions, UserSummary } from '../../models/vessel-security.models';
import { VesselUserService } from '../../services/user/vessel-user.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-added-users-grid',
  templateUrl: './added-users-grid.component.html',
  styleUrls: ['./added-users-grid.component.scss'],
})


@Output('itemRemoved')
export class AddedUsersGridComponent implements OnInit {
  @Input() data: UserSummary[];
  @Input() userPermissions: UserPermissions;
  @Input() rolePermissions: RolePermissions;
  @Output() itemRemoved: EventEmitter<UserSummary> = new EventEmitter<UserSummary>();

  loading: boolean;
  gridData: UserSummary[];

  id: number;

  constructor(
    private roleService: VesselRoleService,
    private vesselUserService: VesselUserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = +params.get('id');
    });

    this.loading = true;
    this.gridData = this.data;

    this.loading = false;
  }

  removeItem(data: UserSummary) {
    const index = this.gridData.findIndex(e => e.id === data.id);
    this.gridData.splice(index, 1);
    this.itemRemoved.emit(data);
  }

  addUser(user: UserOption) {
    this.vesselUserService.getUser(user.id).subscribe(data => {
      this.gridData.push(data);
      this.itemRemoved.emit(data);
    });
  }
}
