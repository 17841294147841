import { KendoGridConfig } from '../../../_foundation/kendo/models/grid.models';

const vesselUserGridConfig: KendoGridConfig = {
  defaultSort: [{ field: 'username', dir: 'asc' }],
  columns:
    [
      {
        field: 'username',
        title: 'Username',
        filter: 'text',
      },
      {
        field: 'firstName',
        title: 'Forename',
        filter: 'text',
      },
      {
        field: 'lastName',
        title: 'Surname',
        filter: 'text',
      },
      {
        field: 'active',
        title: 'Active',
        filter: 'boolean',
        _width: 120
      },
      {
        field: 'creationTime',
        title: 'Created',
        filter: 'date'
      },
      {
        field: 'roleNamesText',
        title: 'Role',
        filterable: false,
        sortable: false,
      },
      {
        field: '_actions',
        title: 'Actions',
        filterable: false,
        sortable: false,
        _width: 80
      }
    ]
};

export default vesselUserGridConfig;
