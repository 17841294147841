import { NgModule } from '@angular/core';
import { DraftRecordControlsComponent } from './components/draft-record-controls/draft-record-controls.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DraftRecordControlsComponent
  ],
  exports: [
    DraftRecordControlsComponent
  ]
})
export class DraftRecordModule { }
