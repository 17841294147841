export class ListOptions<T> {
  original: T[] = [];
  current: T[] = [];

  populate(collection: T[]): void {
    this.original = collection;
    this.current = collection;
  }

  reset() {
    this.current = this.original;
  }
}

export class ReorderListPermissions {
  canViewListItem: boolean;
  canEditListItem: boolean;
  canArchiveListItem: boolean;
  canDeleteListItem: boolean;
}

export class ReorderListActions {
  hasViewButton: boolean;
  hasEditButton: boolean;
  hasArchiveButton: boolean;
  hasDeleteButton: boolean;
}
