import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'security',
    loadChildren: 'app/_security/security.module#SecurityModule'
  },
  {
    path: 'vessel-users',
    loadChildren: 'app/vessel-user/vessel-user.container.module#VesselUserContainerModule'
  },
  {
    path: 'vessel',
    loadChildren: 'app/vessels/vessels.container.module#VesselsContainerModule'
  },
  {
    path: 'vessel-report',
    loadChildren: 'app/vessel-report/vessel-report.module#VesselReportModule'
  },
  {
    path: 'locations',
    loadChildren: 'app/locations/locations.container.module#LocationsContainerModule'
  },
  {
    path: 'location-type',
    loadChildren: 'app/location-type/location-type.container.module#LocationTypeContainerModule'
  },
  {
    path: 'coa',
    loadChildren: 'app/coas/coa.container.module#CoaContainerModule'
  },
  {
    path: 'operation',
    loadChildren: 'app/operation/operation.container.module#OperationContainerModule'
  },
  {
    path: 'operation-classification',
    loadChildren: 'app/operation-classification/operation-classification.container.module#OperationClassificationContainerModule'
  },
  {
    path: 'project-types',
    loadChildren: 'app/project-types/project-types.container.module#ProjectTypesContainerModule'
  },
  {
    path: 'projects',
    loadChildren: 'app/projects/projects.container.module#ProjectsContainerModule'
  },
  {
    path: 'backoffice/replication/log',
    loadChildren: 'app/infrastructure/backoffice-replication-log/backoffice-replication-log.container.module#BackofficeReplicationLogContainerModule'
  },
  {
    path: 'releases',
    loadChildren: 'app/infrastructure/releases/releases.container.module#ReleasesContainerModule'
  },
  {
    path: 'change-audit',
    loadChildren: 'app/change-audit/change-audit.container.module#ChangeAuditContainerModule'
  },
  {
    path: '**',
    redirectTo: '/vessel-report/grid',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

