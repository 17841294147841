import { Injectable } from '@angular/core';
import { KendoGridDataConfig } from '../../../_foundation/kendo/models/grid.models';
import { HttpClient } from '@angular/common/http';
import { RoleEditor, RoleMetadata, RolePermissions, UserOption } from '../../models/vessel-security.models';
import { Observable } from 'rxjs/Observable';
import { EditorWithMetadataResponse } from '../../../_shared/models/editor.models';
import { AppConfig } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class VesselRoleService {

  constructor(
    private http: HttpClient,
  ) {
  }
  getGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/vesselroles/grid/data`;
    return { name: 'roles', url: url };
  }

  getUserOptions(editor: RoleEditor, searchText: string = ''): Observable<UserOption[]> {
    let url = `${AppConfig.settings.apiUrl}/vesselroles/user-options`;
    url += `?searchText=${searchText}`;
    return this.http.post<UserOption[]>(url, editor);
  }

  archiveRole(id: number): Observable<void> {
    const url = `${AppConfig.settings.apiUrl}/vesselroles/${id}/archive`;
    return this.http.post<void>(url, null);
  }

  enableRole(id: number): Observable<void> {
    const url = `${AppConfig.settings.apiUrl}/vesselroles/${id}/enable`;
    return this.http.post<void>(url, null);
  }

  deleteRole(id: number): Observable<void> {
    const url = `${AppConfig.settings.apiUrl}/vesselroles/${id}/delete`;
    return this.http.post<void>(url, null);
  }

  getEditorWithMetadata(id?: number): Observable<EditorWithMetadataResponse<RoleEditor, RoleMetadata>> {
    const url = `${AppConfig.settings.apiUrl}/vesselroles/` + (!!id ? `${id}/editor-with-metadata` : 'editor-with-metadata');
    return this.http.get<EditorWithMetadataResponse<RoleEditor, RoleMetadata>>(url);
  }

  saveEditor(editor: RoleEditor): Observable<RoleEditor> {
    const url = `${AppConfig.settings.apiUrl}/vesselroles/editor`;
    return this.http.post<RoleEditor>(url, editor);
  }

  getPermissions(): Observable<RolePermissions> {
    const url = `${AppConfig.settings.apiUrl}/security/roles/permissions`;
    return this.http.get<RolePermissions>(url);
  }
}
