import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Location, isPlatformBrowser } from '@angular/common';

@Injectable()
export class CacheBusterService {
  private readonly intervalMinutes: number = 30;
  private readonly lastCacheKey: string = 'last_cache';

  constructor(
    private router: Router,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  init(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setLastLoadTime();
    }

    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        if (this.requiresCacheRefresh()) {
          this.location.go(e.url);
        }
      }
    });
  }

  private setLastLoadTime(): void {
    const time = new Date().getTime();
    window.localStorage.setItem(this.lastCacheKey, `${time}`);
  }

  private requiresCacheRefresh(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const current = new Date(window.localStorage.getItem(this.lastCacheKey));
      return current.getTime() + this.getIntervalMilliseconds() > new Date().getTime();
    }
  }

  private getIntervalMilliseconds() {
    return this.intervalMinutes * 60 * 100;
  }
}
