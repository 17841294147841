import { KendoGridConfig } from '../../../_foundation/kendo/models/grid.models';

const vesselRolesViewUserGridConfig: KendoGridConfig = {
  defaultSort: [{ field: 'username', dir: 'asc' }],
  columns:
    [
      {
        field: 'username',
        title: 'Username',
        sortable: false,
        filterable: false,
      },
      {
        field: 'roleNamesText',
        title: 'Role',
        sortable: false,
        filterable: false,
      },
      {
        field: '_actions',
        title: 'Actions',
        filterable: false,
        sortable: false,
        _width: 80
      }
    ]
};

export default vesselRolesViewUserGridConfig;
