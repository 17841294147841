import { Component, OnInit } from '@angular/core';
import { User } from '../../../_security/models/security.models';
import { AuthService } from '../../../_foundation/services/auth/auth.service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  title: string;
  user: User;
  links: any[] = [
    { href: '/plans', 'text': 'Plans' },
    { href: '/security/users', 'text': 'Users' }
  ];

  constructor(
    private authService: AuthService
  ) {
    this.title = AppConfig.settings.appName;
  }

  ngOnInit() {
    this.user = this.authService.currentUser();
  }
}
