import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { PtKendoDropdownFilterComponent } from '../components/pt-kendo-dropdown-filter/pt-kendo-dropdown-filter.component';
import { PtKendoMultiselectFilterComponent } from '../components/pt-kendo-multiselect-filter/pt-kendo-multiselect-filter.component';
import { PtKendoGridDirective } from '../directives/pt-kendo-grid/pt-kendo-grid.directive';

@NgModule({
  imports: [
    CommonModule,
    DropDownsModule
  ],
  declarations: [
    PtKendoDropdownFilterComponent,
    PtKendoMultiselectFilterComponent,
    PtKendoGridDirective
  ],
  exports: [
    PtKendoDropdownFilterComponent,
    PtKendoMultiselectFilterComponent,
    PtKendoGridDirective
  ]
})

export class PtKendoModule { }
