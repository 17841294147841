export function windowScrollTop() {
  if (!window)
    return;
  let timeOut;
  (function s() {
    if (document.body.scrollTop !== 0 || document.documentElement.scrollTop != 0) {
      window.scrollBy(0, -50);
      timeOut = setTimeout(() => { s(); }, 10);
    } else {
      clearTimeout(timeOut);
    }
  })();
}
