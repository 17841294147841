import { Component, OnInit, ViewChild } from '@angular/core';
import {
  PermissionGroup,
  RoleEditor,
  RoleMetadata,
  RolePermissions,
  UserOption,
  UserPermissions,
  UserSummary
} from '../../models/vessel-security.models';
import { ValidationResult } from '../../../_foundation/models/validation.models';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { windowScrollTop } from '../../../_foundation/utils/window.utils';
import { VesselRoleService } from '../../services/role/vessel-role.service';
import { AddedUsersGridComponent } from '../added-users-grid/added-users-grid.component';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { NextRouteService } from '../../../_shared/services/next-route-service/next-route.service';
import { ConfirmDialogComponent } from '../../../_shared/components/confirm-dialog/confirm-dialog.component';
import { cloneDeep } from 'lodash';
import { DirtyService } from '../../../_shared/services/dirty-service/dirty.service';
import { VesselUserService } from '../../services/user/vessel-user.service';

@Component({
  selector: 'app-vessel-role-editor',
  templateUrl: './vessel-role-editor.component.html',
  styleUrls: ['./vessel-role-editor.component.scss']
})
export class VesselRoleEditorComponent implements OnInit {
  @ViewChild('usersGrid') userGrid: AddedUsersGridComponent;
  @ViewChild('userInput') userInput: DropDownListComponent;
  @ViewChild('dirtyConfirmDialog') dirtyConfirmDialog: ConfirmDialogComponent;

  rolePermissions: RolePermissions = new RolePermissions();
  userPermissions: UserPermissions = new UserPermissions();

  creating: boolean;
  editor: RoleEditor;
  metadata: RoleMetadata;
  lockedDayPermissionGroup: PermissionGroup;
  validationResult: ValidationResult;

  previousEditor: RoleEditor;
  dirtyOpen: boolean = false;
  forceLeave: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private roleService: VesselRoleService,
    private userService: VesselUserService,
    private nextRouteService: NextRouteService,
    private toastrService: ToastrService,
    private dirtyService: DirtyService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id: number = +params.get('id');

      this.creating = !id;

      this.roleService.getPermissions()
        .subscribe(result => {
          this.rolePermissions = result;
        });

      this.userService.getPermissions()
        .subscribe(result => {
          this.userPermissions = result;
        });

      this.roleService.getEditorWithMetadata(id)
        .subscribe(response => {
          this.editor = response.editor;
          this.metadata = response.metadata;
          this.metadata.permissionGroups.forEach(value => {
            value.permissions.forEach(permission => {
              permission.selected = this.editor.permissions.some(value2 => value2.id === permission.id)
                || (this.creating && permission.isDefault);
            });
          });

          const lockedDayGroupIndex = this.metadata.permissionGroups.findIndex(e => e.name === 'Locked Day');
          this.lockedDayPermissionGroup = this.metadata.permissionGroups[lockedDayGroupIndex];
          this.previousEditor = cloneDeep(this.editor);
        });
    });
  }

  submit(): void {
    this.setPermissions();

    this.validationResult = null;
    this.roleService
      .saveEditor(this.editor)
      .subscribe(() => {
        this.toastrService.success('This record was saved successfully', 'Save successful');
        this.forceLeave = true;
        this.router.navigate(['/vessel-users/roles']).then();
      },
        error => {
          if (error.status && error.status === 412) {
            this.validationResult = error.error;
            const toast = this.toastrService.error('Click to view', 'Validation error');
            toast.onTap.subscribe(() => windowScrollTop());
          }
        });
  }

  setPermissions() {
    this.editor.permissions = [];
    this.metadata.permissionGroups.forEach(value => {
      this.editor.permissions = this.editor.permissions.concat(value.permissions.filter(value1 => value1.selected));
    });
  }

  canDeactivate() {

    this.setPermissions();

    if (this.checkDirty() && !this.forceLeave) {
      this.dirtyConfirmDialog.open();
      return false;
    }
    return true;
  }

  userSelected(user: UserOption) {
    if (!user)
      return;
    const index = this.metadata.users.findIndex(e => e.id === user.id);
    // this.metadata.users.splice(index, 1);
    this.userGrid.addUser(user);
  }

  onUserFilterChanged(searchText: string) {
    this.roleService.getUserOptions(this.editor, searchText).subscribe(users => {
      this.metadata.users = users;
    });
  }

  updateUserOptions() {
    this.roleService.getUserOptions(this.editor).subscribe(users => {
      this.metadata.users = users;
    });
  }

  itemRemovedReceived(item: UserSummary) {
    this.updateUserOptions();
  }

  cancel() {
    // this.router.navigate(['/vessel/grid']).then();
    this.nextRouteService.outputRoute('/vessel-users/roles');
    this.attemptLeave();
  }

  attemptLeave() {
    this.router.navigateByUrl(this.nextRouteService.nextRoute);
  }

  leave() {
    this.forceLeave = true;
    this.attemptLeave();
  }

  checkDirty() {
    if (this.previousEditor.name !== this.editor.name) {
      return true;
    }

    if (this.previousEditor.active !== this.editor.active) {
      return true;
    }

    if (this.dirtyService.checkArrays(this.previousEditor.permissions.map(p => p.id), this.editor.permissions.map(p => p.id))) {
      return true;
    }

    if (this.dirtyService.checkArrays(this.previousEditor.users.map(p => p.id), this.editor.users.map(p => p.id))) {
      return true;
    }

    return false;
  }
}
