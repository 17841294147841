import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KendoGridDataConfig } from '../../../_foundation/kendo/models/grid.models';
import { AreasLocationsPermissions, AssociatedProjectOption, LocationsEditor } from '../../models/locations-model';
import { Observable } from 'rxjs/Observable';
import { ApiOption } from '../../../_foundation/models/base.models';
import { EditorWithPermissions, IdWithReason } from '../../../_shared/models/editor.models';
import { AppConfig } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(
    private http: HttpClient,
    ) {}

  getEditor(id: number): Observable<LocationsEditor> {
    const url = `${AppConfig.settings.apiUrl}/location/${id}/editor`;
    return this.http.get<LocationsEditor>(url);
  }

  saveEditor(editor: LocationsEditor): Observable<LocationsEditor> {
    const url = `${AppConfig.settings.apiUrl}/location/editor`;
    return this.http.post<LocationsEditor>(url, editor);
  }

  getPermissions(): Observable<AreasLocationsPermissions> {
    const url = `${AppConfig.settings.apiUrl}/location/permissions`;
    return this.http.get<AreasLocationsPermissions>(url);
  }

  getEditorWithPermissions(id: number): Observable<EditorWithPermissions<LocationsEditor, AreasLocationsPermissions>> {
    const url = `${AppConfig.settings.apiUrl}/location/${id}/editor-with-permissions`;
    return this.http.get<EditorWithPermissions<LocationsEditor, AreasLocationsPermissions>>(url);
  }

  getLocationGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/location/grid/data`;
    return { name: 'location', url: url };
  }

  getLocationAssociatedProjectGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/location/associatedproject/grid/data`;
    return { name: 'associatedProject', url: url };
  }

  getAreaAssociatedProjectGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/area/associatedproject/grid/data`;
    return { name: 'associatedProject', url: url };
  }

  getLocationCoaGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/location/grid/data/coa`;
    return { name: 'location-pop-up-v2', url: url };
  }

  getLocationStageGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/location/grid/data/stage`;
    return { name: 'location-pop-up-v2', url: url };
  }

  getLocationPhaseGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/location/grid/data/phase`;
    return { name: 'location-pop-up-v2', url: url };
  }

  getLocationAreaGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/location/grid/data/withareas`;
    return { name: 'location-area', url: url };
  }

  getProjectLocationGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/location/grid/data/project`;
    return { name: 'location-project', url: url };
  }

  getLocationSummaryGridDataConfig(phaseId?: number, stageId?: number): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/location/summary/grid/data/${phaseId}/${stageId}`;
    return { name: 'location-summary', url: url };
  }

  getLocationSummaryWithNoAreasGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/location/nolinkedareasummary/grid/data`;
    return { name: 'location-summary-no-area', url: url };
  }

  deleteLocation(id: number): Observable<boolean> {
    const url = `${AppConfig.settings.apiUrl}/location/${id}/delete`;
    return this.http.get<boolean>(url);
  }

  getGeodeticDatumOptions(): Observable<ApiOption<string>[]> {
    const url = `${AppConfig.settings.apiUrl}/location/geodeticdatum/options`;
    return this.http.get<ApiOption<string>[]>(url);
  }

  getAssociatedProjectOptions(): Observable<AssociatedProjectOption[]> {
    const url = `${AppConfig.settings.apiUrl}/location/project/options`;
    return this.http.get<AssociatedProjectOption[]>(url);
  }

  getProjectionZoneOptions(): Observable<ApiOption<string>[]> {
    const url = `${AppConfig.settings.apiUrl}/location/projectionzone/options`;
    return this.http.get<ApiOption<string>[]>(url);
  }

  getCardinalDirectionOptions(): Observable<ApiOption<string>[]> {
    const url = `${AppConfig.settings.apiUrl}/location/cardinaldirection/options`;
    return this.http.get<ApiOption<string>[]>(url);
  }

  getLocationTypeOptions(active?: boolean): Observable<ApiOption<number>[]> {
    let url = `${AppConfig.settings.apiUrl}/locationtype/options`;
    if (active) {
      url = `${AppConfig.settings.apiUrl}/locationtype/options/active=${active}`;
    }
    return this.http.get<ApiOption<number>[]>(url);
  }

  getAreaOptions(): Observable<any[]> {
    const url = `${AppConfig.settings.apiUrl}/area/options`;
    return this.http.get<ApiOption<number>[]>(url);
  }

  archive(reason: IdWithReason): Observable<boolean> {
    const url = `${AppConfig.settings.apiUrl}/location/archive`;
    return this.http.post<boolean>(url, reason);
  }
}
