import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { KendoGridDataConfig } from '../../../_foundation/kendo/models/grid.models';
import { UserMetadata, UserPermissions, VesselUserGridFiltersModel } from '../../models/vessel-security.models';
import { PtKendoGridDirective } from '../../../_foundation/kendo/directives/pt-kendo-grid/pt-kendo-grid.directive';
import { State } from '@progress/kendo-data-query';
import vesselRolesViewUserGridConfig from './vessel-role-user-grid-config';
import { VesselUserService } from '../../services/user/vessel-user.service';

@Component({
  selector: 'app-vessel-role-grid-view-users',
  templateUrl: './vessel-role-grid-view-users.component.html',
  styleUrls: ['./vessel-role-grid-view-users.component.scss']
})
export class VesselRoleGridViewUsersComponent implements OnInit {
  @ViewChild('viewUsers') content;
  @ViewChild('dialogActions') actions;
  @ViewChild('grid') grid?: GridComponent;
  @ViewChild('ptKendoGrid') ptKendoGrid: PtKendoGridDirective;
  @Input() userPermissions: UserPermissions;

  private dialogRef: DialogRef;

  name = 'vessel-role-view-users';
  gridConfig = vesselRolesViewUserGridConfig;
  gridDataConfig: KendoGridDataConfig;
  state: State = {
    take: 15,
    sort: vesselRolesViewUserGridConfig.defaultSort
  };
  filters: VesselUserGridFiltersModel = new VesselUserGridFiltersModel();
  data: GridDataResult;
  metadata: UserMetadata;
  loading: boolean;

  constructor(
    private dialogService: DialogService,
    private vesselUserService: VesselUserService
  ) { }

  ngOnInit() {
  }

  open(data: any) {
    this.loading = true;
    this.filters.roleIds = [];
    this.filters.roleIds.push(data.id);
    this.gridDataConfig = this.vesselUserService.getRolesViewUsersGridDataConfig();

    this.dialogRef = this.dialogService.open({
      title: data.name + ' - Associated Users',
      content: this.content,
      actions: this.actions,
      width: 1000
    });

    setTimeout(() => {
      this.ptKendoGrid.filters = this.filters;
      this.ptKendoGrid.updateGrid().subscribe();
      this.loading = false;
    });
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
