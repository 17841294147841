import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as StackTrace from 'stacktrace-js';
import { environment } from '../../../environments/environment';
import { IAppConfig } from '../models/configuration.models';
import { AppConfig } from '../../app.config';

@Injectable()
export class FoundationErrorHandler extends ErrorHandler {
  private http: HttpClient;

  constructor(private injector: Injector) {
    // The true paramter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
    // when an error happens. If we do not rethrow, bootstrap will always succeed.
    // super(true);
    super();

    this.http = injector.get(HttpClient);
  }

  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      const httpError = error as HttpErrorResponse;
      if (httpError.status === 401 || httpError.status === 412)
        return; // Don't throw 412 errors

      error = httpError.error;
    }

    const message = error.message ? error.message : error.toString();

    StackTrace
      .fromError(error)
      .then(stackFrames => {
        const model = this.buildLog(message, stackFrames.map(e => `${e.functionName}() - ${e.fileName}:${e.lineNumber}`));

        this.http.post(`${AppConfig.settings.apiUrl}/error/send`, model).subscribe();
      }, err => {
        console.warn('StackTrace could not parse error');
      });

    console.error(error);
  }

  private buildLog(message: string, stackTrace: string[]) {
    const error: ErrorEmailModel = {
      time: new Date().toISOString(),
      origin: window.location.origin,
      url: window.location.pathname + window.location.search,
      message: message,
      stackTrace: stackTrace,
      buildNumber: AppConfig.settings.version
    };

    return error;
  }
}

export interface ErrorEmailModel {
  time: string;
  message: string;
  stackTrace: string[];
  url: string;
  origin: string;
  buildNumber: string;
}
