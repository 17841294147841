import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkAuthorised(state);
  }

  private checkAuthorised(state: RouterStateSnapshot): Observable<boolean> {
    return of(true);
    // TODO: SETUP WINDOWS Authentication
    // return this.http
    //   .get(`${AppConfig.settings.apiUrl}/auth/verify`)
    //   .pipe(catchError((err) => {
    //     return of(null);
    //   }))
    //   .pipe(switchMap((result) => {
    //     if (AppConfig.settings.auth.useWindowsAuthentication) {
    //       return of(true);
    //     }
    //
    //     if (state.url === AppConfig.settings.auth.loginRoute) {
    //       if (!this.authService.isAuthenticated) {
    //         this.authService.logout();
    //         return of(true);
    //       }
    //
    //       this.router.navigateByUrl(AppConfig.settings.auth.defaultRoute);
    //       return of(false);
    //     }
    //
    //     if (!this.authService.isAuthenticated) {
    //       this.authService.logout();
    //       this.router.navigateByUrl(AppConfig.settings.auth.loginRoute);
    //     }
    //
    //     return of(this.authService.isAuthenticated);
    //   }));
  }
}
