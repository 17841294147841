import { Directive, ElementRef, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

/**
 * Directive for watching form changes. Use with IDirtyRecordGuard to stop users from navigating
 * away when changes have been made to a form without saving.
 *
 * @export
 * @class DirtyRecordDirective
 *
 * @usage
 * Implement IDirtyRecordGuard on the component which contains the form.
 * Add this DirtyRecordDirective to the form and bind the [(dirty)] attribute value from IDirtyRecordGuard.
 * Call watch() when you want to start watching the form changes.
 * Add DirtyRecordGuard to the canDeactivate route property to configure the dirty form alert.
 */
@Directive({
  selector: 'form[dirtyRecord]'
})
export class DirtyRecordDirective {
  @Output() dirtyChange$: EventEmitter<boolean> = new EventEmitter();

  @Input()
  get dirty(): boolean {
    return this.dirtyValue;
  }

  set dirty(value: boolean) {
    this.dirtyValue = value;
    this.dirtyChange$.emit(this.dirtyValue);
  }

  get isWatching(): boolean { return this.watchingValue; }

  private watchingValue: boolean = false;
  private dirtyValue: boolean = false;

  constructor(
    private el: ElementRef,
    private ngForm: NgForm
  ) { }

  watch() {
    this.reset();

    if (this.isWatching)
      return;

    setTimeout(() => {
      this.watchingValue = true;

      this.ngForm.valueChanges
        .takeWhile((val, i) => this.watchingValue)
        .subscribe(e => this.dirty = true);
    });
  }

  stopWatching() {
    this.watchingValue = false;
    this.reset();
  }

  reset() {
    this.dirty = false;
  }
}
