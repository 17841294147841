import { KendoGridConfig } from '../../../_foundation/kendo/models/grid.models';

const gridConfig: KendoGridConfig = {
  defaultSort: [{ field: 'name', dir: 'asc' }],
  columns:
    [
      {
        field: 'name',
        title: 'Name',
        filter: 'text'
      },
      {
        field: 'active',
        title: 'Active',
        filter: 'boolean'
      },
      {
        field: 'numberOfUsers',
        title: 'No. Of Users',
        filter: 'numeric'
      },
      {
        field: '_actions',
        title: 'Actions',
        filterable: false,
        sortable: false,
        _width: 80
      }
    ]
};

export default gridConfig;
