import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() title: string;
  @Input() body: string;
  @Input() confirmOnly?: boolean;
  @Input() message: string;
  @Input() list: string[];
  @Output() confirmFunction: EventEmitter<void> = new EventEmitter();
  @Output() cancelFunction: EventEmitter<void> = new EventEmitter();

  opened: boolean;

  constructor() { }

  ngOnInit() {
  }

  public confirm() {
    this.confirmFunction.emit();
    this.closeDialog();
  }

  public cancel() {
    this.cancelFunction.emit();
    this.closeDialog();
  }

  public closeDialog() {
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }
}
