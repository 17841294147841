import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NextRouteService {
  @Output() routeChanged$: EventEmitter<string> = new EventEmitter();

  nextRoute: string;

  constructor() { }

  outputRoute(route: string) {
    this.routeChanged$.emit(route);
    this.nextRoute = route;
  }
}
