import { Component, ViewChild } from '@angular/core';
import { CacheBusterService } from './_foundation/services/cache-buster/cache-buster.service';
import '@progress/kendo-angular-intl/locales/en-GB/all';
import '../styles/ag-grid.tweaks.scss';
import { AppConfig } from './app.config';
import { VersioningService } from './_foundation/services/versioning/versioning.service';
import {
  IntegrationManagementDialogComponent
} from './infrastructure/integration-management/components/integration-management-dialog/integration-management-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('integrationManagementDialog') integrationManagementDialog: IntegrationManagementDialogComponent;

  environmentLabel: string = AppConfig.settings.environmentName;
  versionNumber: string = AppConfig.settings.version;
  showAdminButtons: boolean;
  config = AppConfig.settings;

  constructor(
    private cacheBuster: CacheBusterService,
    private versioningService: VersioningService
  ) {
    this.versioningService.checkVersionNumber();
    cacheBuster.init();

    this.showAdminButtons = this.config.environmentName.toLowerCase().includes('int')
      || this.config.environmentName.toLowerCase().includes('integration');
  }

  openIntegrationManagementDialog() {
    this.integrationManagementDialog.open();
  }
}
