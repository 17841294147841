import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ToastrModule} from 'ngx-toastr';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/takeWhile';
import {FoundationModule} from './_foundation/foundation.module';
import {SharedModule} from './_shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppConfig} from './app.config';
import {AuthGuardService} from './_foundation/services/auth-guard/auth-guard.service';
import {AuthInterceptor} from './_foundation/services/auth-interceptor/auth.interceptor';
import {AuthService} from './_foundation/services/auth/auth.service';
import {VesselUserModule} from './vessel-user/vessel-user.module';
import {SecurityService} from './_security/services/security/security.service';
import {DatePipe} from '@angular/common';
import {IntegrationManagementModule} from './infrastructure/integration-management/integration-management.module';
import {
  MSAL_INSTANCE,
  MsalModule,
  MsalService
} from '@azure/msal-angular';
import {MSALFactory} from './msalFactory';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'app-seajacks-back-office' }),
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    LoadingBarHttpClientModule,
    ModalModule.forRoot(),
    AppRoutingModule,
    FoundationModule,
    SharedModule,
    VesselUserModule,
    IntegrationManagementModule,
    MsalModule
  ],
  providers: [
    AppConfig,
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {provide: MSAL_INSTANCE, useFactory: MSALFactory},
    MsalService,
    AuthService,
    AuthGuardService,
    SecurityService,
    DatePipe,
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
