import { Injectable } from '@angular/core';
import { ActionSheetComponent } from './action-sheet.component';

@Injectable()
export class ActionSheetService {
  openComponent: ActionSheetComponent;

  toggle(nextComponent: ActionSheetComponent = null) {
    if (this.openComponent && this.openComponent != nextComponent)
      this.openComponent.close();
    this.openComponent = nextComponent;
  }
}