import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { _throw } from 'rxjs/observable/throw';
import { AuthenticationRequest, AuthenticationResponse } from '../../models/auth.models';
import { AppConfig } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authStateChange$: EventEmitter<boolean> = new EventEmitter();

  get isAuthenticated(): boolean {
    // TODO: REMOVE WHEN AUTH IS DONE!
    // return !!localStorage.getItem(this.AUTH_TOKEN_KEY);
    return true;
  }

  private readonly AUTH_TOKEN_KEY: string = 'auth_token';
  private readonly USER_KEY: string = 'auth_user';
  private readonly USERNAME_KEY: string = 'auth_username';
  private readonly REMEMBER_KEY: string = 'auth_remember';

  private user: any;

  constructor(
    private http: HttpClient
  ) {
    if (!AppConfig)
      throw new Error('AuthService requires config to be configured as an IAppConfig implementation using the APP_CONFIG provider token');
    else if (!AppConfig.settings.auth) {
      // tslint:disable-next-line:max-line-length
      throw new Error('AuthService requires authConfig to be configured in an IAppConfig implementation using the APP_CONFIG provider token');
    } else if (!AppConfig.settings.auth.defaultRoute || !AppConfig.settings.auth.loginRoute) {
      throw new Error('authConfig for APP_CONFIG provider token must have both defaultRoute and loginRoute set');
    }
  }

  currentUser<TUser>(): TUser {
    if (!this.user) {
      this.user = this.getStoredUser();
    }
    return this.user;
  }

  login<TUser>(request: AuthenticationRequest): Observable<AuthenticationResponse<TUser>> {
    const url = `${AppConfig.settings.baseApiUrl}${AppConfig.settings.auth.authTokenUrl}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http
      .post<AuthenticationResponse<TUser>>(url, this.getApiLoginRequest(request), { headers: headers })
      .mergeMap(res => {
        this.saveToken(res.access_token);
        this.setUser(res.user);
        this.setRememberedUser(request);
        this.authStateChange$.emit(this.isAuthenticated);
        return Observable.of(res);
      })
      .catch((err) => {
        err = !!err.error && (typeof(err.error) === 'object') ? err.error : err;
        return _throw(err);
      });
  }

  logout(): void {
    if (localStorage.getItem(this.REMEMBER_KEY) !== 'true') {
      localStorage.removeItem(this.USERNAME_KEY);
    }

    localStorage.removeItem(this.AUTH_TOKEN_KEY);
    localStorage.removeItem(this.USER_KEY);
    this.authStateChange$.emit(this.isAuthenticated);
  }

  getAuthHeader(): string {
    return `Bearer ${localStorage.getItem(this.AUTH_TOKEN_KEY)}`;
  }

  latestUsername(): string {
    return localStorage.getItem(this.USERNAME_KEY);
  }

  public saveToken(token: string): void {
    localStorage.setItem(this.AUTH_TOKEN_KEY, token);
  }

  private setUser(user: any): void {
    this.user = user;
    this.setStoredUser(user);
  }

  private setStoredUser(user: any): void {
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));
  }

  private getStoredUser(): any {
    return JSON.parse(localStorage.getItem(this.USER_KEY));
  }

  private setRememberedUser(request: AuthenticationRequest): void {
    localStorage.setItem(this.REMEMBER_KEY, `${request.remember}`);
    localStorage.setItem(this.USERNAME_KEY, request.username);
  }

  private getApiLoginRequest(request: AuthenticationRequest): string {
    return `grant_type=password&username=${request.username}&password=${request.password}`;
  }
}
