export class SidebarModel {
  constructor(
    title: string,
    route: string,
    icon: string,
    children: SidebarModel[],
    permission: boolean) {
    this.title = title;
    this.route = route;
    this.icon = icon;
    this.children = children;
    this.permission = permission;
  }

  title: string;
  route: string;
  icon: string;
  children: SidebarModel[];
  permission: boolean;
}
