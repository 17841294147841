import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { VesselService } from '../../../vessels/services/vessel.service';
import vesselGridConfig from '../../../vessels/components/vessel-grid/vessel-grid.config';
import { KendoGridConfig, KendoGridDataConfig } from '../../../_foundation/kendo/models/grid.models';
import gridConfig from '../../../_security/components/user-grid/user-grid-config';
import { BulkSelectorGridComponent } from '../bulk-selector-grid/bulk-selector-grid.component';
import { BulkSelectorService } from '../../services/bulk-selector-service/bulk-selector.service';
import { cloneDeep } from 'lodash';
import { PtKendoGridDirective } from '../../../_foundation/kendo/directives/pt-kendo-grid/pt-kendo-grid.directive';
import { SelectEvent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-bulk-selector',
  templateUrl: './bulk-selector.component.html',
  styleUrls: ['./bulk-selector.component.scss']
})
export class BulkSelectorComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() gridConfig: KendoGridConfig;
  @Input() gridDataConfig: KendoGridDataConfig;
  @Input() selectedIds: number[] = [];
  @Output() saveEvent = new EventEmitter<number[]>();
  @Output() cancelEvent = new EventEmitter<number[]>();
  @ViewChild('normalGrid') normalGrid: PtKendoGridDirective;
  @ViewChild('selectedGrid') selectedGrid: PtKendoGridDirective;

  searchTerm: string = '';
  originalSelectedIds: number[] = [];

  selectedGridConfig: KendoGridConfig;
  selectedGridDataConfig: KendoGridDataConfig;
  searchDisabled: boolean;

  constructor(
    private vesselService: VesselService,
    private bulkSelectorService: BulkSelectorService
  ) { }

  ngOnInit() {
    this.originalSelectedIds = cloneDeep(this.selectedIds);
    this.clearGridCaches();
  }

  clearGridCaches() {
    // clear these states on load or previously edited states will effect this popup, i.e the grid will be on the wrong page
    localStorage.removeItem('location-summary-grid-state');
    localStorage.removeItem('location-summary-selected-grid-state');
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'gridDataConfig': {
            if (this.gridDataConfig.name != null) {
              this.selectedGridDataConfig = cloneDeep(this.gridDataConfig);
              this.selectedGridDataConfig.name = this.selectedGridDataConfig.name + '-selected';
            }
            break;
          }
        }
      }
    }
  }



  setIds(ids: number[]) {
    if (!!ids) {
      this.selectedIds = ids;
    }
  }

  search() {
    if (this.normalGrid) {
      this.normalGrid.state.skip = 0;
    }

    if (this.selectedGrid) {
      this.selectedGrid.state.skip = 0;
    }

    this.bulkSelectorService.search(this.searchTerm);
  }

  close() {
    this.selectedIds = cloneDeep(this.originalSelectedIds);
    this.cancelEvent.emit(this.originalSelectedIds);
  }

  save() {
    this.saveEvent.emit(this.selectedIds);
  }

  onTabSelect($event: SelectEvent) {
    if ($event.title.toLowerCase().includes('selected')) {
      this.searchDisabled = true;
    } else {
      this.searchDisabled = false;
    }
  }
}
