import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { IAppConfig } from './_foundation/models/configuration.models';

@Injectable()
export class AppConfig {
  static settings: IAppConfig;

  constructor(private handler: HttpBackend) {
  }

  public load(): Promise<void> {
    const jsonFile = environment.config;
    return new Promise<void>((resolve, reject) => {
      const httpClient = new HttpClient(this.handler);
      httpClient
        .get<IAppConfig>(jsonFile)
        .toPromise()
        .then((response: IAppConfig) => {
          AppConfig.settings = response;
          resolve();
        })
        .catch((response: any) => {
          reject(`Unable to load file ${jsonFile}: ${JSON.stringify(response)}`);
        });
    });
  }
}
