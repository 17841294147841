import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonParseService {
  parseDates(model: any) {
    // tslint:disable-next-line:forin
    for (const i in model) {
      if (typeof (model[i]) === 'object') {
        this.parseDates(model[i]);
        continue;
      } else if (typeof (model[i]) !== 'string' || (<string>model[i]).indexOf(':') === -1) {
        continue;
      }

      const date = Date.parse(model[i]);
      if (!isNaN(date))
        model[i] = new Date(model[i]);
    }
  }
}
