import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WeatherSetupEditor } from '../../../projects/models/projects-model';
import { AppConfig } from '../../../app.config';
import { IntegrationManagementSummary } from '../models/integration-management-models';

@Injectable({
  providedIn: 'root'
})
export class IntegrationManagementService {

  constructor(
    private http: HttpClient,
  ) { }

  getSummaries(): Observable<IntegrationManagementSummary[]> {
    const url = `${AppConfig.settings.apiUrl}/integration-management/summaries/get`;
    return this.http.get<IntegrationManagementSummary[]>(url);
  }

  saveSummaries(summaries: IntegrationManagementSummary[]): Observable<any> {
    const url = `${AppConfig.settings.apiUrl}/integration-management/summaries/save`;
    return this.http.post<WeatherSetupEditor>(url, summaries);
  }
}
