import { NgModule } from '@angular/core';
import { DirtyRecordDirective } from './directives/dirty-record.directive';
import { DirtyRecordGuard } from './guards/dirty-record-guard';

@NgModule({
  declarations: [DirtyRecordDirective],
  providers: [DirtyRecordGuard],
  exports: [DirtyRecordDirective]
})
export class DirtyRecordModule {}
