import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoundationModule } from '../../_foundation/foundation.module';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import {
  IntegrationManagementDialogComponent
} from './components/integration-management-dialog/integration-management-dialog.component';

@NgModule({
  imports: [
    DialogModule,
    MultiSelectModule,
    FormsModule,
    CommonModule,
    SwitchModule,
    FoundationModule

  ],
  declarations: [
    IntegrationManagementDialogComponent,
  ],
  exports: [
    IntegrationManagementDialogComponent
  ]
})
export class IntegrationManagementModule { }
