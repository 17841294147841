export interface EditorWithMetadataResponse<TEditor, TMetadata> {
  editor: TEditor;
  metadata: TMetadata;
}

export interface EditorWithPermissions<TEditor, TPermissions> {
  editor: TEditor;
  permissions: TPermissions;
}

export class Option {
  name: string;
  id: number;
}

export class ParentOption {
  name: string;
  id: number;
  parentId: number;
}

export class ChangeReason {
  reason: string;
  date?: Date;
}

export class IdWithReason {
  reason: ChangeReason;
  value: number;
}
