import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  title: string = 'Ooops!';
  message: string = 'Something went wrong';
  isRestrictedMessage: boolean = false;
  adminContact: string;
  mailTo: string;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.adminContact = AppConfig.settings.adminContact;
    this.mailTo = `mailto:${AppConfig.settings.adminContact}`;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.title)
        this.title = params.title;

      if (params.message) {
        if (params.message === 'Restricted content')
          this.isRestrictedMessage = true;
        else
          this.message = params.message;
      }
    });
  }

}
