import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FoundationModule } from '../_foundation/foundation.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ChangeReasonDialogComponent } from './components/change-reason-dialog/change-reason-dialog.component';
import { BulkSelectorComponent } from './components/bulk-selector/bulk-selector.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BulkSelectorGridComponent } from './components/bulk-selector-grid/bulk-selector-grid.component';
import { BodyModule, GridModule } from '@progress/kendo-angular-grid';
import { NgSelectModule } from '@ng-select/ng-select';
import { PtKendoModule } from '../_foundation/kendo/modules/pt.kendo.module';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopUpGridComponent } from './components/pop-up-grid/pop-up-grid.component';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { GridDialogComponent } from './components/grid-dialog/grid-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        DropDownsModule,
        FoundationModule,
        ButtonsModule,
        DialogModule,
        LayoutModule,
        GridModule,
        NgSelectModule,
        PtKendoModule,
        InputsModule,
        DatePickerModule,
        BodyModule,
    ],
  declarations: [
    NavbarComponent,
    SidebarComponent,
    ConfirmDialogComponent,
    ChangeReasonDialogComponent,
    BulkSelectorComponent,
    BulkSelectorGridComponent,
    GridDialogComponent,
    PopUpGridComponent,
  ],
  exports: [
    NavbarComponent,
    SidebarComponent,
    ConfirmDialogComponent,
    ChangeReasonDialogComponent,
    BulkSelectorComponent,
    BulkSelectorGridComponent,
    GridDialogComponent,
    PopUpGridComponent,
  ]
})
export class SharedModule { }
