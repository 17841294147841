import { Injectable } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { IAppConfig } from '../../models/configuration.models';

export class LocalStorageItem {
  key: string;
  value: any;
}

@Injectable()
export class VersioningService {
  public config: IAppConfig = AppConfig.settings;
  private readonly VERSION_NUMBER_KEY: string = 'version_number';
  // list of items that will not be deleted when refreshing local storage
  // start the key with * to act as a wildcard. i.e *-draft will exclude all keys from deletion with '-draft' in it
  private readonly excludedKeys: string[] = [
    'refresh_token',
    'auth_token',
    'auth_username',
    'auth_user',
    'auth_remember',
    'access_token',
    'history_items',
    '*-draft',
  ];

  constructor() {}

  // check savedVersion number against config version, and prompt user to reload if they don't match
  public checkVersionNumber() {
    const savedVersion = localStorage.getItem(this.VERSION_NUMBER_KEY);

    if (!savedVersion || savedVersion !== this.config.version) {
      console.log('version change detected, refreshing local storage');
      this.clearLocalStorage();
    }
  }

  private clearLocalStorage: () => void = () => {
    const excludedItems = this.getExcludedItems();

    localStorage.clear();

    this.saveExcludedItems(excludedItems);

    localStorage.setItem(this.VERSION_NUMBER_KEY, this.config.version);

    // window.location.reload();
  }

  private getExcludedItems(): LocalStorageItem[] {
    // storage contains all items from localStorage in an array of arrays, inner array[0] == key, inner array[1] == value
    const storage = Object.entries(localStorage);
    const excludedItems: LocalStorageItem[] = [];

    this.excludedKeys.forEach((key) => {
      // if wildcard key then find all saved keys that match it
      if (key.startsWith('*')) {
        // remove '*' from key
        const wildcardString = key.substring(1);
        const matchingItems = storage.filter((e) =>
          e[0].endsWith(wildcardString)
        );

        matchingItems.forEach((item) => {
          excludedItems.push({ key: item[0], value: item[1] });
        });
      } else {
        const savedItem = localStorage.getItem(key);

        if (savedItem) {
          excludedItems.push({ key, value: savedItem });
        }
      }
    });

    return excludedItems;
  }

  private saveExcludedItems(excludedItems: LocalStorageItem[]) {
    excludedItems.forEach((item) => {
      localStorage.setItem(item.key, item.value);
    });
  }
}
