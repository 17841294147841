export class SidebarPermissions {
  canAccessSetup: boolean;
  canAccessVessels: boolean;
  canAccessUserAndRoles: boolean;
  canAccessBackOfficeUsers: boolean;
  canAccessBackOfficeRoles: boolean;
  canAccessVesselUsers: boolean;
  canAccessVesselRoles: boolean;
  canAccessLocationManagement: boolean;
  canAccessLocationTypes: boolean;
  canAccessLocations: boolean;
  canAccessCoas: boolean;
  canAccessOperationManagement: boolean;
  canAccessOperationClassifications: boolean;
  canAccessOperations: boolean;
  canAccessProjects: boolean;
  canAccessReports: boolean;
  canAccessAudit: boolean;
}
