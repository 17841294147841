import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../services/auth/auth.service';
import { SecurityService } from '../../../../_security/services/security/security.service';
import { User } from '../../../../_security/models/security.models';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'pt-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavComponent implements OnInit {
  @Input() title: string;

  user: User;
  environmentLabel: string;
  logoUrl: string;
  optionsOpen: boolean = false;
  loggedIn: boolean = false;

  navOptionsElement;
  settingsIcon = faCog;

  @HostListener('document:click', ['$event'])
  click(event) {
    if (!this.navOptionsElement) {
      this.navOptionsElement = document.getElementById('nav-options-dropdown-container');
    }

    const optionClicked = event.target.classList.contains('option');

    if (this.optionsOpen && this.navOptionsElement && (optionClicked || !this.navOptionsElement.contains(event.target))) {
      this.optionsOpen = false;
    }
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private securityService: SecurityService
  ) {
    this.environmentLabel = AppConfig.settings.environmentName;
    this.title = AppConfig.settings.appName;
    this.logoUrl = AppConfig.settings.logoUrl;
  }

  ngOnInit() {
    this.securityService.getUser().subscribe(user => {
      this.user = user;
    });
  }

  toggleOptionsDropdown() {
    this.optionsOpen = !this.optionsOpen;
  }

  logout() {
    this.authService.logout();
    this.router.navigate([AppConfig.settings.auth.loginRoute]);
  }
}
