import { CommonModule } from '@angular/common';
import { ElementRef, ErrorHandler, NgModule } from '@angular/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ActionSheetComponent } from './components/action-sheet/action-sheet.component';
import { ActionSheetService } from './components/action-sheet/action-sheet.service';
import { ErrorComponent } from './components/error/error.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { TickCrossComponent } from './components/tick-cross/tick-cross.component';
import { ValidationSummaryComponent } from './components/validation-summary/validation-summary.component';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { FoundationRoutes } from './foundation-routing.module';
import { DirtyRecordModule } from './modules/dirty-record/dirty-record.module';
import { DraftRecordModule } from './modules/draft-record/draft-record.module';
import { CacheBusterService } from './services/cache-buster/cache-buster.service';
import { PermissionService } from './services/permission/permission.service';
import { FoundationErrorHandler } from './utils/error-handler';
import { NavComponent } from './components/layout/nav/nav.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { ReorderListComponent } from './components/reorder-list/reorder-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { VersioningService } from './services/versioning/versioning.service';

@NgModule({
  imports: [
    CommonModule,
    CollapseModule,
    FontAwesomeModule,
    FoundationRoutes,
    DragDropModule
  ],
  declarations: [
    NavComponent,
    SidebarComponent,
    FooterComponent,
    LoadingComponent,
    ErrorComponent,
    PageTitleComponent,
    ValidationSummaryComponent,
    TickCrossComponent,
    ActionSheetComponent,
    ClickOutsideDirective,
    ReorderListComponent,
  ],
  providers: [
    {provide: ErrorHandler, useClass: FoundationErrorHandler},
    ActionSheetService,
    PermissionService,
    CacheBusterService,
    VersioningService
  ],
  exports: [
    NavComponent,
    SidebarComponent,
    LoadingComponent,
    ErrorComponent,
    PageTitleComponent,
    ValidationSummaryComponent,
    TickCrossComponent,
    ActionSheetComponent,
    ClickOutsideDirective,
    DirtyRecordModule,
    DraftRecordModule,
    ReorderListComponent,
  ]
})
export class FoundationModule {
}
