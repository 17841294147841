import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VesselEditor, VesselFilters, VesselPermissions } from '../models/vessels.models';
import { EditorWithPermissions, Option } from '../../_shared/models/editor.models';
import { KendoGridDataConfig } from '../../_foundation/kendo/models/grid.models';
import { Observable } from 'rxjs/Observable';
import { AppConfig } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class VesselService {

  constructor(
    private http: HttpClient,
  ) {

  }

  getEditor(id: number): Observable<VesselEditor> {
    const url = `${AppConfig.settings.apiUrl}/vessel/${id}/editor`;
    return this.http.get<VesselEditor>(url);
  }

  getPermissions(): Observable<VesselPermissions> {
    const url = `${AppConfig.settings.apiUrl}/vessel/permissions`;
    return this.http.get<VesselPermissions>(url);
  }

  getEditorWithPermissions(id: number): Observable<EditorWithPermissions<VesselEditor, VesselPermissions>> {
    const url = `${AppConfig.settings.apiUrl}/vessel/${id}/editor-with-permissions`;
    return this.http.get<EditorWithPermissions<VesselEditor, VesselPermissions>>(url);
  }

  saveEditor(editor: VesselEditor): Observable<VesselEditor> {
    const url = `${AppConfig.settings.apiUrl}/vessel/editor`;
    return this.http.post<VesselEditor>(url, editor);
  }

  getClassOptions() {
    return this.http.get<Option[]>(`${AppConfig.settings.apiUrl}/vessel/typeoptions`);
  }

  getClassOptionsFiltered(vessels: number[]) {
    return this.http.post<Option[]>(`${AppConfig.settings.apiUrl}/vessel/typeoptions`, vessels);
  }

  getVesselOptions() {
    return this.http.get<Option[]>(`${AppConfig.settings.apiUrl}/vessel/options`);
  }

  getVesselOptionsFilter(filters: VesselFilters) {
    const url = `${AppConfig.settings.apiUrl}/vessel/options`;
    return this.http.post<Option[]>(url, filters);
  }

  getVesselGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/vessel/grid/data`;
    return { name: 'vessel', url: url };
  }

  getVesselGridStageDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/vessel/grid/data/stage`;
    return { name: 'vessel-pop-up', url: url };
  }

  getVesselGridPhaseDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/vessel/grid/data/phase`;
    return { name: 'vessel-pop-up', url: url };
  }

  archiveVessel(id: number): Observable<boolean> {
    const url = `${AppConfig.settings.apiUrl}/vessel/${id}/archive`;
    return this.http.get<boolean>(url);
  }

  GetVessels(filters: VesselFilters): Observable<VesselEditor[]> {
    const url = `${AppConfig.settings.apiUrl}/vessel/get`;
    return this.http.post<VesselEditor[]>(url, filters);
  }
}
