import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NgSelectModule } from '@ng-select/ng-select';
import { FoundationModule } from '../_foundation/foundation.module';
import { PtKendoModule } from '../_foundation/kendo/modules/pt.kendo.module';
import { SharedModule } from '../_shared/shared.module';
import { VesselRoleService } from './services/role/vessel-role.service';
import { VesselUserGridComponent } from './components/vessel-user-grid/vessel-user-grid.component';
import { VesselUserEditorComponent } from './components/vessel-user-editor/vessel-user-editor.component';
import { VesselUserRoleGridComponent } from './components/vessel-role-grid/vessel-user-role-grid.component';
import { VesselRoleEditorComponent } from './components/vessel-role-editor/vessel-role-editor.component';
import { AddedUsersGridComponent } from './components/added-users-grid/added-users-grid.component';
import { VesselRoleGridViewUsersComponent } from './components/vessel-role-grid-view-users/vessel-role-grid-view-users.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { VesselUserService } from './services/user/vessel-user.service';
import { RouterModule } from '@angular/router';
import { DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    GridModule,
    DropDownsModule,
    NgSelectModule,
    FoundationModule,
    PtKendoModule,
    SharedModule,
    DialogModule,
    InputsModule,
    DatePickerModule,
    DateInputsModule,
    DatePickerModule,
  ],
  providers: [
    VesselUserService,
    VesselRoleService
  ],
  declarations: [
    VesselUserGridComponent,
    VesselUserEditorComponent,
    VesselUserRoleGridComponent,
    VesselRoleEditorComponent,
    AddedUsersGridComponent,
    VesselRoleGridViewUsersComponent
  ]
})
export class VesselUserModule { }
