import { Component, OnInit, ViewChild, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { PtKendoGridDirective } from '../../../_foundation/kendo/directives/pt-kendo-grid/pt-kendo-grid.directive';
import { KendoGridDataConfig } from '../../../_foundation/kendo/models/grid.models';
import { State } from '@progress/kendo-data-query';
import { cloneDeep } from 'lodash';
import { ApiOption } from '../../../_foundation/models/base.models';
import { LocationsService } from '../../../locations/services/locations-service/locations.service';

@Component({
  selector: 'app-pop-up-grid',
  templateUrl: './pop-up-grid.component.html',
  styleUrls: ['./pop-up-grid.component.scss']
})
export class PopUpGridComponent implements OnInit {
  @ViewChild('grid') grid?: GridComponent;
  @ViewChild('ptKendoGrid') ptKendoGrid: PtKendoGridDirective;

  @Output() saveEvent = new EventEmitter<void>();
  @Output() cancelEvent = new EventEmitter<void>();

  @Input() gridConfig;
  @Input() gridDataConfig: KendoGridDataConfig;
  @Input() title: string;
  @Input() filterId: number;

  state: State;
  data: GridDataResult;
  locationTypeOptionsOriginal: ApiOption<number>[] = [];
  locationTypeOptions: ApiOption<number>[] = [];

  constructor(
    private locationService: LocationsService
  ) { }

  close() {
    this.cancelEvent.emit();
  }

  save() {
    this.saveEvent.emit();
  }

  async ngOnInit() {
    if (this.gridDataConfig && this.ptKendoGrid) {
      this.ptKendoGrid.removeGridCache(this.gridDataConfig.name);
    }

    this.state = {
      take: 15,
      skip: 0,
      sort: this.gridConfig.defaultSort
    };

    this.locationService.getLocationTypeOptions().subscribe(response => {
      this.locationTypeOptionsOriginal = response;
      this.locationTypeOptions = response;
    });
  }

  resetColumns(): void {
    this.ptKendoGrid
      .resetGrid({ columns: true })
      .subscribe();
  }

  resetFilters(): void {
    this.ptKendoGrid
      .resetGrid({ state: true, otherFilters: true })
      .subscribe();
  }

  locationTypeFilterChange(event: string) {
    event = event.toUpperCase();
    this.locationTypeOptions = cloneDeep(this.locationTypeOptionsOriginal.filter(e => e.name.toUpperCase().includes(event)));
  }
}
