import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ValidationResult } from '../../../_foundation/models/validation.models';
import { windowScrollTop } from '../../../_foundation/utils/window.utils';
import { RolePermissions, UserEditor, UserMetadata, UserPermissions } from '../../models/vessel-security.models';
import { VesselUserService } from '../../services/user/vessel-user.service';
import { cloneDeep } from 'lodash';
import { ConfirmDialogComponent } from '../../../_shared/components/confirm-dialog/confirm-dialog.component';
import { NextRouteService } from '../../../_shared/services/next-route-service/next-route.service';
import { Observable } from 'rxjs/Observable';
import { DirtyService } from '../../../_shared/services/dirty-service/dirty.service';
import { VesselRoleService } from '../../services/role/vessel-role.service';

@Component({
  selector: 'app-vessel-user-editor',
  templateUrl: './vessel-user-editor.component.html',
  styleUrls: ['./vessel-user-editor.component.scss']
})
export class VesselUserEditorComponent implements OnInit {
  @ViewChild('dirtyConfirmDialog') dirtyConfirmDialog: ConfirmDialogComponent;

  rolePermissions: RolePermissions = new RolePermissions();
  userPermissions: UserPermissions = new UserPermissions();

  creating: boolean;
  editor: UserEditor;
  metadata: UserMetadata;
  validationResult: ValidationResult;

  previousEditor: UserEditor;
  dirtyOpen: boolean = false;
  forceLeave: boolean = false;
  breadcrumbs: string;
  passcodeResetEnabled: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private vesselUserService: VesselUserService,
    private toastrService: ToastrService,
    private nextRouteService: NextRouteService,
    private dirtyService: DirtyService,
    private roleService: VesselRoleService,
    private userService: VesselUserService,
  ) { }

  ngOnInit() {
    Observable.combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(results => {
      const paramsMap = results[0];
      const queryParamsMap = results[1];
      const id: number = +paramsMap.get('id');
      if (!!queryParamsMap.get('breadcrumbs')) {
        this.breadcrumbs = queryParamsMap.get('breadcrumbs');
      }

      this.creating = !id;

      this.roleService.getPermissions()
        .subscribe(result => {
          this.rolePermissions = result;
        });

      this.userService.getPermissions()
        .subscribe(result => {
          this.userPermissions = result;
        });

      this.vesselUserService.getEditorWithMetadata(id)
        .subscribe(response => {
          this.editor = response.editor;

          if (!!this.editor.accessUntil) {
            this.editor.accessUntil = new Date(this.editor.accessUntil);
          }

          this.metadata = response.metadata;

          this.metadata.roleOptions.forEach(value => {
            value.selected = this.editor.roleIds.some(
              value2 => value2 === value.id
            );
          });

          this.setRoles();
          this.previousEditor = cloneDeep(this.editor);
        });
    });
  }

  canDeactivate() {
    this.setRoles();

    if (this.checkDirty() && !this.forceLeave) {
      this.dirtyConfirmDialog.open();
      return false;
    }
    return true;
  }


  submit(): void {
    this.validationResult = null;

    this.editor.disabled = !this.editor.active;

    this.forceLeave = true;

    this.setRoles();

    this.vesselUserService
      .saveEditor(this.editor)
      .subscribe(() => {
        this.toastrService
          .success('This record was saved successfully', 'Save successful');
        this.router
          .navigate(['/vessel-users/users']).then();
      },
        error => {
          if (error.status && error.status === 412) {
            this.validationResult = error.error;
            const toast = this.toastrService
              .error('Click to view', 'Validation error');
            toast.onTap.subscribe(() => windowScrollTop());
          }
        });
  }

  cancel() {
    // this.router.navigate(['/vessel/grid']).then();
    if (!!this.breadcrumbs) {
      this.nextRouteService.outputRoute(this.breadcrumbs);
    } else {
      this.nextRouteService.outputRoute('/vessel-users/users');
    }
    this.attemptLeave();
  }

  attemptLeave() {
    this.router.navigateByUrl(this.nextRouteService.nextRoute);
  }

  leave() {
    this.forceLeave = true;
    this.attemptLeave();
  }

  setRoles() {
    this.editor.roleIds = [];

    this.editor.roleIds = this.metadata.roleOptions
      .filter(value1 => value1.selected)
      .map(value => value.id);
  }

  checkDirty() {
    if (this.previousEditor.active !== this.editor.active) {
      return true;
    }

    if (this.previousEditor.username !== this.editor.username) {
      return true;
    }

    if (this.previousEditor.disabled !== this.editor.disabled) {
      return true;
    }

    if (this.previousEditor.firstName !== this.editor.firstName) {
      return true;
    }

    if (this.previousEditor.lastName !== this.editor.lastName) {
      return true;
    }

    if (this.previousEditor.password !== this.editor.password) {
      return true;
    }

    if (this.previousEditor.passwordConfirmation !== this.editor.passwordConfirmation) {
      return true;
    }

    if (this.dirtyService.checkArrays(this.previousEditor.roleIds, this.editor.roleIds)) {
      return true;
    }

    if (this.dirtyService.checkArrays(this.previousEditor.permissionIds, this.editor.permissionIds)) {
      return true;
    }

    if (this.dirtyService.checkDates(this.previousEditor.accessUntil, this.editor.accessUntil)) {
      return true;
    }

    return false;
  }

  resetPasscodeChange() {
    if (!this.passcodeResetEnabled) {
      this.editor.password = null;
      this.editor.passwordConfirmation = null;
    }
  }
}
