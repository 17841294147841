import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../_foundation/services/auth/auth.service';
import {Subject} from 'rxjs/Subject';
import {takeUntil} from 'rxjs/operators';
import {SidebarModel} from '../../models/sidebar.models';
import {SidebarService} from '../../services/sidebar-service/sidebar.service';
import {SidebarPermissions} from '../../models/permission.models';
import {NextRouteService} from '../../services/next-route-service/next-route.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  authenticated: boolean = false;
  permissions: SidebarPermissions = new SidebarPermissions();

  private destroy$: Subject<any> = new Subject();

  model: SidebarModel[] = [];

  parentModel: SidebarModel[][] = [];
  parentTexts: string[] = [];
  currentParentText: string;
  currentModel: SidebarModel[] = this.model;
  currentRoute: string;

  constructor(
    private authService: AuthService,
    private sidebarService: SidebarService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private nextRouteService: NextRouteService) {
    this.authenticated = authService.isAuthenticated;
  }

  ngOnInit() {
    this.checkPermissions();

    this.authService.authStateChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(authenticated => {
        this.authenticated = authenticated;
        this.checkPermissions();
      });
  }

  ngOnDestroy() {
    this.destroy$.complete();
  }

  clickEvent(selected: SidebarModel) {
    if (selected.route) {
      this.nextRouteService.outputRoute(selected.route);
      this.router.navigateByUrl(selected.route);
      this.currentRoute = selected.route;
    }

    this.showChildren(selected);
  }

  showChildren(selected: SidebarModel) {
    if (selected.children.length > 0) {
      this.parentModel.push(this.currentModel);
      this.parentTexts.push(this.currentParentText);
      this.currentParentText = selected.title;
      this.currentModel = selected.children;
    }
  }

  showParent() {
    this.currentModel = this.parentModel.pop();
    this.currentParentText = this.parentTexts.pop();
  }

  checkPermissions() {
    this.sidebarService
      .getPermissions()
      .subscribe(result => {
        this.permissions = result;
        this.model = [
          new SidebarModel('Setup', '', 'fa-cogs', [
            new SidebarModel('Vessels', '/vessel/grid', 'fa-ship', [], this.permissions.canAccessVessels),
            new SidebarModel('Projects', '', 'fa-file-alt', [
              new SidebarModel('Project Types', '/project-types/grid', 'fa-cogs', [], this.permissions.canAccessProjects),
              new SidebarModel('Projects', '/projects/grid', 'fa-anchor', [], this.permissions.canAccessProjects),
              new SidebarModel('Weather Monitoring', '/projects/weather-monitoring/grid', 'fa-cloud', [], this.permissions.canAccessProjects),
            ], this.permissions.canAccessProjects),
            new SidebarModel('Users & Roles', '', 'fa-users', [
              new SidebarModel('Back Office App Users', '/security/users', 'fa-users', [], this.permissions.canAccessBackOfficeUsers),
              new SidebarModel('Back Office App Roles', '/security/roles', 'fa-user-md', [], this.permissions.canAccessBackOfficeRoles),
              new SidebarModel('Vessel App Users', '/vessel-users/users', 'fa-users', [], this.permissions.canAccessVesselUsers),
              new SidebarModel('Vessel App Roles', '/vessel-users/roles', 'fa-user-md', [], this.permissions.canAccessVesselRoles)
            ], this.permissions.canAccessUserAndRoles),
            new SidebarModel('Location Management', '', 'fa-location-arrow', [
              new SidebarModel('Location Types', '/location-type/grid', 'fa-compass', [], this.permissions.canAccessLocationTypes),
              new SidebarModel('Locations', '/locations/grid', 'fa-map-marker-alt', [], this.permissions.canAccessLocations),
            ], this.permissions.canAccessLocations || this.permissions.canAccessLocationTypes),
            new SidebarModel('COAs', '/coa/grid', 'fa-file', [], this.permissions.canAccessCoas),
            new SidebarModel('Operation Management', '', 'fa-cogs', [
              new SidebarModel(
                'Operation Classification',
                '/operation-classification/grid',
                'fa-align-justify',
                [],
                this.permissions.canAccessOperations),
              new SidebarModel(
                'Operation Sub Classification',
                '/operation-classification/sub/grid',
                'fa-align-justify',
                [],
                this.permissions.canAccessOperations),
              new SidebarModel(
                'Operations',
                '/operation/grid',
                'fa-wrench',
                [],
                this.permissions.canAccessOperations),
            ], this.permissions.canAccessOperations),
          ], this.permissions.canAccessSetup),
          new SidebarModel('Reports', '/vessel-report/grid', 'fa-file', [], this.permissions.canAccessReports),
          new SidebarModel('Data Replication', '/backoffice/replication/log', 'fa-database', [], this.permissions.canAccessReports),
          new SidebarModel('Releases', '/releases', 'fa-code-branch', [], this.permissions.canAccessReports),
          new SidebarModel('Change Audit', '/change-audit/grid', 'fa-history', [], this.permissions.canAccessAudit)
        ];
        this.currentModel = this.model;
        this.changeDetector.detectChanges();
      });
  }
}
