import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { SidebarPermissions } from '../../models/permission.models';
import { AppConfig } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  constructor(
    private http: HttpClient,
  ) { }

  getPermissions(): Observable<SidebarPermissions> {
    const url = `${AppConfig.settings.apiUrl}/permission/sidebar`;
    return this.http.get<SidebarPermissions>(url);
  }

}
