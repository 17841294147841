import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AppConfig } from '../../../app.config';

@Injectable()
export class PermissionService {
  private permissions: any;
  private permissionsTask: Observable<boolean>;

  constructor(
    private http: HttpClient
  ) { }

  getPermissions<T>(): T {
    return this.permissions;
  }

  loadPermissions<T>(): Observable<boolean> {
    if (this.permissionsTask)
      return this.permissionsTask;

    return this.permissionsTask = this.http
      .get<T>(`${AppConfig.settings.apiUrl}/security/permissions`)
      .mergeMap((permissions => {
        this.permissions = permissions;
        return Observable.of(true);
      }));
  }
}
