import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChangeReason } from '../../models/editor.models';

@Component({
  selector: 'app-change-reason-dialog',
  templateUrl: './change-reason-dialog.component.html',
  styleUrls: ['./change-reason-dialog.component.scss']
})
export class ChangeReasonDialogComponent implements OnInit {
  @Input() title: string;
  @Input() body: string;
  @Input() includeDate: boolean = false;
  @Input() includeDateText: string = 'Effective From';
  @Input() list: string[];
  @Input() message: string;
  @Output() confirmFunction: EventEmitter<ChangeReason> = new EventEmitter();
  @Output() cancelFunction: EventEmitter<void> = new EventEmitter();

  opened: boolean;

  reasonForChange = new ChangeReason();

  constructor() { }

  ngOnInit() {
  }

  public confirm() {
    this.confirmFunction.emit(this.reasonForChange);
    this.closeDialog();
  }

  public cancel() {
    this.cancelFunction.emit();
    this.closeDialog();
  }

  public closeDialog() {
    this.reasonForChange = new ChangeReason();
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }
}
