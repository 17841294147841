import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReorderListActions, ReorderListPermissions } from '../../models/helper.models';

@Component({
  selector: 'app-reorder-list',
  templateUrl: './reorder-list.component.html',
  styleUrls: ['./reorder-list.component.scss']
})
export class ReorderListComponent implements OnInit {
  @Input() data: any;
  @Input() gridConfig: any;
  @Input() gridHeightPx: number;
  @Input() gridWidthPx: number;
  @Input() useColumnWidthPx: boolean;
  @Input() permissions: ReorderListPermissions;
  @Input() actionOptions: ReorderListActions;
  @Input() loading: boolean;
  @Input() noDataText: string;
  @Input() hideInactiveRows: boolean;
  @Output() dropEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() archiveEvent = new EventEmitter<any>();
  @Output() unArchiveEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  @Output() columnClickEvent = new EventEmitter<any>();

  displayActive: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  calculateColumnWidth(columns: any[], columnWidth: number): number {
    if (this.useColumnWidthPx) {
      if (columns && columns.length > 0) {
        return columnWidth;
      }
    } else {
      if (columns && columns.length > 0) {
        const sumColumnWidths = this.sumColumnWidths(columns);
        const widthPercent = (columnWidth / sumColumnWidths) * 100;

        return widthPercent;
      }
    }
  }

  sumColumnWidths(columns: any[]) {
    let sum = 0;
    columns.forEach(column => {
      if (column._width) {
        sum += parseFloat(column._width);
      }
    });

    return sum;
  }

  drop(event: any) {
    if (event.previousIndex !== event.currentIndex) {
      this.dropEvent.emit(event);
    }
  }

  openEditor(item: any) {
    this.editEvent.emit(item);
  }

  archiveItem(item: any) {
    this.archiveEvent.emit(item);
  }

  unArchiveItem(item: any) {
    this.unArchiveEvent.emit(item);
  }

  deleteItem(item: any) {
    this.deleteEvent.emit(item);
  }

  columnClicked(item: any, column: any) {
    this.columnClickEvent.emit({row: item, column: column});
  }

  dataContainsInactive() {
    return this.data && this.data.some(e => !e.active);
  }

  toggleArchivedSteps() {
    this.displayActive = !this.displayActive;
  }
}
