import { Injectable } from '@angular/core';
import { Role, User } from '../../models/security.models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AppConfig } from '../../../app.config';

@Injectable()
export class SecurityService {
  private _user: User;
  private baseUrl = '';

  constructor(
    private http: HttpClient,
  ) {
    this.baseUrl = `${AppConfig.settings.apiUrl}/security/users`;
  }

  getUser(): Observable<User> {
    if (!!this._user)
      return Observable.of(this._user);

    return this.http
      .get<User>(this.baseUrl + '/user')
      .mergeMap(user => {
        this._user = user;
        return Observable.of(user);
      });
  }

  hasRole(tag: string): Observable<boolean> {
    return this
      .getUser()
      .mergeMap(user => {
        return Observable.of(user.roles.some(r => r.tag === tag));
      });
  }

  getAvailableRoles() {
    return this.http.get<Role[]>(this.baseUrl + '/roles');
  }

}
