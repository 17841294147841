import { Component, OnInit, ViewChild, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { PtKendoGridDirective } from '../../../_foundation/kendo/directives/pt-kendo-grid/pt-kendo-grid.directive';
import { KendoGridDataConfig, KendoGridColumn, UpdateGridOptions } from '../../../_foundation/kendo/models/grid.models';
import { process, State, isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { BulkSelectorService } from '../../services/bulk-selector-service/bulk-selector.service';
import { SelectedOperation } from '../../../projects/models/projects-model';

@Component({
  selector: 'app-bulk-selector-grid',
  templateUrl: './bulk-selector-grid.component.html',
  styleUrls: ['./bulk-selector-grid.component.scss']
})
export class BulkSelectorGridComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid?: GridComponent;
  @ViewChild('ptKendoGrid') ptKendoGrid: PtKendoGridDirective;
  @Input() gridConfig;
  @Input() gridDataConfig: KendoGridDataConfig;
  @Input() selectedIds: number[] = [];
  @Input() areaIds: number[] = [];
  @Input() selectedPage: boolean = false;
  @Input() post: boolean;
  @Input() selected: boolean = false;
  @Input() searchTerm: string;
  @Input() actionsDisabled: boolean = false;
  @Input() useOldPage: boolean = false;
  @Output() idsChange = new EventEmitter<number[]>();
  pageSizes: number[] = [5, 15, 25, 50, 100, 500];
  apiFilters: any;

  state: State;

  data: GridDataResult;
  newData: GridDataResult;

  gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  selectedFilter: FilterDescriptor = {
    field: 'selected',
    operator: 'eq',
    value: true,
  };

  queryFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  constructor(
    private bulkSelectorService: BulkSelectorService,
  ) { }

  ngOnDestroy() {
  }

  async ngOnInit() {

    if (!this.selectedIds) {
      this.selectedIds = [];
    }

    if (!this.areaIds) {
      this.areaIds = [];
    }

    this.apiFilters = { areaIds: this.areaIds, selectedIds: this.selectedIds, selectedPage: this.selectedPage };

    this.state = {
      take: 10,
      sort: this.gridConfig.defaultSort,
      filter: this.gridFilter,
      skip: 0
    };

    this.bulkSelectorService.searchTermChange$
      .subscribe(res => {
        this.queryChanged(res);
      });

    if (this.selected) {
      this.gridFilter.filters = [this.selectedFilter];
    }

    this.ptKendoGrid.dataChange.subscribe(res => {
      this.data.data.forEach(item => {
        item.selected = this.checkIds(item.id);
      });
    });
  }

  queryChanged(searchTerm: string) {
    this.generateQueryFilter(searchTerm);
    const options: UpdateGridOptions = {
      preventDataUpdate: false,
      searchTerm: searchTerm,
    };

    this.ptKendoGrid.updateGrid(options).subscribe();
  }

  generateQueryFilter(searchTerm: string) {
    const filter: CompositeFilterDescriptor = {
      logic: 'or',
      filters: []
    };

    this.gridConfig.columns.forEach(column => {
      const typedColumn = column as KendoGridColumn;
      const innerFilter: FilterDescriptor = {
        field: typedColumn.field,
        value: searchTerm,
        operator: 'contains',
      };
      filter.filters.push(innerFilter);
    });

    const position = this.gridFilter.filters.findIndex(p => isCompositeFilterDescriptor(p));

    if (!position) {
      this.gridFilter.filters.push(filter);
    } else {
      this.gridFilter.filters[position] = filter;
    }

    this.state.filter = this.gridFilter;
  }

  resetColumns(): void {
    this.ptKendoGrid
      .resetGrid({ columns: true })
      .subscribe();
  }

  resetFilters(): void {
    this.ptKendoGrid
      .resetGrid({ state: true, otherFilters: true })
      .subscribe();
  }

  checkIds(id: number): boolean {
    return !!this.selectedIds.find(p => p === id);
  }

  select(id: number) {
    if (!id) {
      return;
    }

    const found = this.selectedIds.find(p => p === id);

    if (!found) {
      this.selectedIds.push(id);
    }

    this.idsChange.emit(this.selectedIds);
  }

  unselect(id: number) {
    if (!id) {
      return;
    }

    this.selectedIds = this.selectedIds.filter(p => p !== id);

    this.idsChange.emit(this.selectedIds);
  }
}
