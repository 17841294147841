import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import {
  PermissionOption,
  RoleOption,
  UserMetadata,
  UserPermissions,
  VesselUserGridFiltersModel
} from '../../models/vessel-security.models';
import { VesselUserService } from '../../services/user/vessel-user.service';
import { KendoGridDataConfig } from '../../../_foundation/kendo/models/grid.models';
import { PtKendoGridDirective } from '../../../_foundation/kendo/directives/pt-kendo-grid/pt-kendo-grid.directive';
import vesselUserGridConfig from './vessel-user-grid-config';

@Component({
  selector: 'app-vessel-users-grid',
  templateUrl: './vessel-user-grid.component.html',
  styleUrls: ['./vessel-user-grid.component.scss']
})
export class VesselUserGridComponent implements OnInit {
  @ViewChild('grid') grid?: GridComponent;
  @ViewChild('ptKendoGrid') ptKendoGrid: PtKendoGridDirective;

  permissions: UserPermissions = new UserPermissions();

  name = 'users';
  gridConfig = vesselUserGridConfig;
  gridDataConfig: KendoGridDataConfig;
  state: State = {
    take: 15,
    sort: vesselUserGridConfig.defaultSort
  };
  filters: VesselUserGridFiltersModel = new VesselUserGridFiltersModel();
  data: GridDataResult;
  metadata: UserMetadata;

  roleOptions: RoleOption[] = [];
  permissionOptions: PermissionOption[] = [];
  exportDataLoading: boolean;

  constructor(
    private vesselUserServer: VesselUserService
  ) { }

  ngOnInit() {
    this.vesselUserServer.getFilterRoleOptions().subscribe(roles => this.roleOptions = roles);
    this.vesselUserServer.getPermissionOptions().subscribe(permissions => this.permissionOptions = permissions);

    this.gridDataConfig = this.vesselUserServer.getVesselUsersGridDataConfig();

    this.vesselUserServer.getPermissions()
      .subscribe(result => {
        this.permissions = result;
      });

    this.vesselUserServer.getMetadata().subscribe(metadata => {
      this.metadata = metadata;
    });
  }

  archive(id: number, setTo: boolean): void {
    if (setTo) {
      this.vesselUserServer.disableUser(id).subscribe(value => {
        this.ptKendoGrid.resetGrid({
          columns: false,
          otherFilters: false,
          state: false,
          update: true
        }).subscribe();
      });
    } else {
      this.vesselUserServer.enableUser(id).subscribe(value => {
        this.ptKendoGrid.resetGrid({
          columns: false,
          otherFilters: false,
          state: false,
          update: true
        }).subscribe();
      });
    }
  }

  resetColumns(): void {
    this.ptKendoGrid
      .resetGrid({ columns: true })
      .subscribe();
  }

  resetFilters(): void {
    this.filters.roleIds = [];
    this.filters.permissionIds = [];
    this.ptKendoGrid
      .resetGrid({ state: true, otherFilters: true })
      .subscribe();
  }

  exportGridData() {
    this.exportDataLoading = true;

    this.ptKendoGrid.exportGridData().subscribe({
      next: () => {
        this.exportDataLoading = false;
      },
      error: () => {
        this.exportDataLoading = false;
      },
    });
  }

  roleOptionChanged() {
    this.grid.loading = true;
    this.state.skip = 0;
    this.ptKendoGrid.filters = this.filters;
    this.ptKendoGrid.updateGrid().subscribe();
    this.grid.loading = false;
  }

  permissionOptionChanged() {
    this.grid.loading = true;
    this.state.skip = 0;
    this.ptKendoGrid.filters = this.filters;
    this.ptKendoGrid.updateGrid().subscribe();
    this.grid.loading = false;
  }
}
