import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'pt-kendo-dropdown-filter',
  template: `
    <kendo-dropdownlist
      class="width-100"
      #list
      [value]="selectedValue"
      [valuePrimitive]="true"
      [data]="data"
      [textField]="textField"
      [valueField]="valueField"
      [defaultItem]="defaultItem"
      [filterable]="filterable"
      (filterChange)="onFilterChange($event)"
      (valueChange)="onChange($event)">
    </kendo-dropdownlist>
  `
})
export class PtKendoDropdownFilterComponent extends BaseFilterCellComponent implements OnInit {
  @Input() filter: CompositeFilterDescriptor;
  @Input() data: any[];
  @Input() textField: string;
  @Input() valueField: string;
  @Input() filterable: boolean;
  @Input() filterPropertyField: string;
  @Output() filterChange: EventEmitter<any> = new EventEmitter();
  @ViewChild("list") list;

  get selectedValue(): any {
    const filter = this.filterByField(this.filterPropertyField);
    return filter ? filter.value : null;
  }

  get defaultItem(): any {
    return {
      [this.textField]: "(All)",
      [this.valueField]: null
    };
  }

  constructor(filterService: FilterService) {
    super(filterService);
  }

  ngOnInit() { }

  onChange(value: any): void {
    let descriptor = value === null || value === ''
      ? this.removeFilter(this.filterPropertyField)
      : this.updateFilter({
        field: this.filterPropertyField,
        operator: "eq",
        value: value
      });
  }

  onFilterChange($event) {
    this.filterChange.next($event);
  }
}
