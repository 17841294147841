import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';

const routes: Routes = [
  {
    path: 'error',
    children: [
      { path: 'error', component: ErrorComponent }
    ]
  }
];

export const FoundationRoutes = RouterModule.forChild(routes);
