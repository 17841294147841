import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { EditorWithMetadataResponse } from '../../../_shared/models/editor.models';
import { PermissionOption, RoleOption, UserEditor, UserMetadata, UserPermissions, UserSummary } from '../../models/vessel-security.models';
import { KendoGridDataConfig } from '../../../_foundation/kendo/models/grid.models';
import { AppConfig } from '../../../app.config';

@Injectable()
export class VesselUserService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getVesselUsersGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/vesselusers/grid/data`;
    return { name: 'vessel-users', url: url };
  }

  getRolesViewUsersGridDataConfig(): KendoGridDataConfig {
    const url = `${AppConfig.settings.apiUrl}/vesselusers/grid/data`;
    return { name: 'vessel-roles-view-users', url: url };
  }

  getEditor(id?: number): Observable<UserEditor> {
    const url = `${AppConfig.settings.apiUrl}/vesselusers/` + (!!id ? `${id}/editor` : 'editor');
    return this.http.get<UserEditor>(url);
  }

  getUser(id: number): Observable<UserSummary> {
    const url = `${AppConfig.settings.apiUrl}/vesselusers/${id}/summary` ;
    return this.http.get<UserSummary>(url);
  }

  enableUser(id: number): Observable<void> {
    const url = `${AppConfig.settings.apiUrl}/vesselusers/${id}/enable` ;
    return this.http.post<void>(url, null);
  }

  disableUser(id: number): Observable<void> {
    const url = `${AppConfig.settings.apiUrl}/vesselusers/${id}/archive` ;
    return this.http.post<void>(url, null);
  }

  getEditorWithMetadata(id?: number): Observable<EditorWithMetadataResponse<UserEditor, UserMetadata>> {
    const url = `${AppConfig.settings.apiUrl}/vesselusers/` + (!!id ? `${id}/editor-with-metadata` : 'editor-with-metadata');
    return this.http.get<EditorWithMetadataResponse<UserEditor, UserMetadata>>(url);
  }

  getMetadata(editor?: UserEditor): Observable<UserMetadata> {
    const url = `${AppConfig.settings.apiUrl}/vesselusers/metadata`;
    return this.http.post<UserMetadata>(url, editor);
  }

  saveEditor(editor: UserEditor): Observable<UserEditor> {
    const url = `${AppConfig.settings.apiUrl}/vesselusers/editor`;
    return this.http.post<UserEditor>(url, editor);
  }

  getFilterRoleOptions() {
    return this.http.get<RoleOption[]>(`${AppConfig.settings.apiUrl}/vesselusers/roleoptions`);
  }

  getPermissionOptions() {
    return this.http.get<PermissionOption[]>(`${AppConfig.settings.apiUrl}/vesselusers/permissionoptions`);
  }

  getPermissions(): Observable<UserPermissions> {
    const url = `${AppConfig.settings.apiUrl}/security/users/permissions`;
    return this.http.get<UserPermissions>(url);
  }
}
