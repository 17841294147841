export interface Role {
  id: number;
  tag: string;
  name: string;
  selected: boolean;
}

export interface Permission {
  id: number;
  tag: string;
  name: string;
  selected: boolean;
  isDefault: boolean;
}

export interface PermissionGroup {
  id: number;
  permissions: Permission[];
  name: string;
}

export interface UserOption {
  id: number;
  name: string;
}

export interface User {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  disabled: boolean;
  active: boolean;
}

export interface UserEditor {
  id: number | null;
  username: string;
  firstName: string;
  lastName: string;
  disabled: boolean;
  active: boolean;
  accessUntil: Date;
  password: string;
  passwordConfirmation: string;
  roleIds: number[];
  permissionIds: number[];
}

export interface UserMetadata {
  roleOptions: Role[];
}

export interface RoleEditor {
  id: number | null;
  guid: string;
  name: string;
  active: boolean;
  numberOfActiveUsers: number;
  permissions: Permission[];
  users: UserSummary[];
}

export interface RoleMetadata {
  permissionGroups: PermissionGroup[];
  users: UserOption[];
}

export interface RoleSummary {
  id: number;
  name: string;
  tag: string;
  active: boolean;
  numberOfUsers: number;
  numberOfInactiveUsers: number;
  numberOfActiveUsers: number;
}

export class UserSummary {
  id: number;
  username: string;
  fullName: string;
  disabled: boolean;
  active: boolean;
  roleNamesText: string;
  creationTime: Date;
  modificationTime: Date;
}

export class RoleOption {
  id: number;
  name: string;
  selected: boolean;
}

export class PermissionOption {
  id: number;
  name: string;
  selected: boolean;
}

export class VesselUserGridFiltersModel {
  roleIds: number[] = [];
  permissionIds: number[] = [];
}


export class RolePermissions {
  canManageRoles: boolean;
  canViewRoles: boolean;
}

export class UserPermissions {
  canManageUsers: boolean;
  canViewUsers: boolean;
}
