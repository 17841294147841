import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntegrationManagementService } from '../../services/integration-management.service';
import { IntegrationManagementSummary } from '../../models/integration-management-models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-integration-management-dialog',
  templateUrl: './integration-management-dialog.component.html',
  styleUrls: ['./integration-management-dialog.component.scss']
})
export class IntegrationManagementDialogComponent implements OnInit {
  @Input() title: string;
  @Input() body: string;
  @Input() message: string;
  @Output() confirmFunction: EventEmitter<void> = new EventEmitter();
  @Output() cancelFunction: EventEmitter<void> = new EventEmitter();

  opened: boolean;
  integrationManagementSummaries: IntegrationManagementSummary[] = [];
  loading: boolean;

  constructor(
    private integrationManagementService: IntegrationManagementService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
  }

  public confirm() {
    this.loading = true;

    this.integrationManagementService.saveSummaries(this.integrationManagementSummaries).subscribe(() => {
      this.toastrService.success('Settings saved successfully', 'Save successful');
      this.loading = false;
      this.closeDialog();
    });
  }

  public cancel() {
    this.cancelFunction.emit();
    this.closeDialog();
  }

  public closeDialog() {
    this.opened = false;
  }

  public open() {
    this.loadSummaryData();
    this.opened = true;
  }

  private loadSummaryData() {
    this.loading = true;

    this.integrationManagementService.getSummaries().subscribe(res => {
      this.integrationManagementSummaries = res;
      this.loading = false;
    });
  }
}
