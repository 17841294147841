import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DirtyService {

  constructor() { }

  checkArrays(array1: any[], array2: any[]) {

    if (!array1 && !array2) {
      return false;
    }

    if (!array1 || !array2) {
      return true;
    }

    const missingIn1 = array1.filter(p => array2.indexOf(p) < 0);

    if (missingIn1.length > 0) {
      return true;
    }

    const missingIn2 = array2.filter(p => array1.indexOf(p) < 0);

    if (missingIn2.length > 0) {
      return true;
    }

    return false;
  }

  checkDates(date1: Date, date2: Date) {
    if (!date1 && !date2) {
      return false;
    }

    if (!date1 || !date2) {
      return true;
    }

    const sameYear = date1.getFullYear() === date2.getFullYear();
    const sameMonth = date1.getMonth() === date2.getMonth();
    const sameDay = date1.getDate() === date2.getDate();
  }

}
